<template>
	<main class="tw-flex tw-flex-col">
		<header class="head">
			<div class="tw-bg-white">
				<div class="tw-flex tw-items-center tw-justify-between tw-h-16">
					<div class="tw-flex tw-items-center">
						<div class="tw-cursor-pointer tw-flex tw-items-center tw-pl-2" @click="$router.push('/index')">
							<img src="@/assets/img/logo-sjzx.png" alt="" class="tw-h-12">
						</div>
						<div class="searchBox tw-ml-4">
							<input class="searchBox-input tw-text-sm tw-px-4 tw-rounded-l-2xl" placeholder="请输入内容" />
							<button class="searchBox-btn tw-text-white tw-bg-red-500 tw-rounded-r-2xl">
								<i class="iconfont icon-sousuo"></i>
							</button>
						</div>
						<button class="publish-btn">
							<span class="tw-ml-1 tw-text-sm">免费发布信息</span>
						</button>
					</div>
					<div class="tw-flex tw-items-center">
						<div class="tw-flex tw-flex-col tw-px-4 tw-border-r tw-border-gray-100">
							<div class="tw-text-sm">客服电话:0716-8888888</div>
						</div>
						<div class="tw-flex tw-items-center tw-px-4 tw-cursor-pointer tw-border-r tw-border-gray-100">
							<i class="iconfont icon-taolunqu tw-text-2xl tw-leading-6"></i>
							<div class="tw-text-sm tw-ml-1">消息</div>
						</div>
						<div class="tw-flex tw-items-center tw-px-4 tw-cursor-pointer tw-border-r tw-border-gray-100">
							<i class="iconfont icon-shezhi tw-text-2xl tw-leading-6"></i>
							<div class="tw-text-sm tw-ml-1">设置</div>
						</div>
						<div class="tw-flex tw-items-center tw-px-4 tw-cursor-pointer">
							<i class="iconfont icon-shequ tw-text-2xl tw-leading-6"></i>
							<div class="tw-text-sm tw-ml-1">我的</div>
							<i class="iconfont icon-arrow-down tw-text-xs tw-ml-2"></i>
						</div>
					</div>
				</div>
			</div>
		</header>
		<div class="tw-pt-8 tw-w-full">
			<div class="userMenu">
				<el-menu router :default-active="$route.path" class="el-menu-vertical-demo" background-color="#383d4a"
					text-color="#fff" active-text-color="#fff">
					<el-menu-item index="/business/job/index">
						<i class="iconfont icon-shouye2"></i>
						<span class="tw-text-base">首页</span>
					</el-menu-item>
					<el-sub-menu index="1">
						<template #title>
							<i class="iconfont icon-work"></i>
							<span class="tw-text-base">我的招聘</span>
						</template>
						<el-menu-item-group>
							<el-menu-item index="/business/job/position">职位管理</el-menu-item>
							<el-menu-item index="/business/job/interestedInMe">对我感兴趣</el-menu-item>
							<el-menu-item index="/business/job/resumeReceive">收到的简历</el-menu-item>
							<el-menu-item index="/business/job/resumeDown">下载的简历</el-menu-item>
							<el-menu-item index="/business/job/interviewRecord">面试记录</el-menu-item>
							<el-menu-item index="/business/job/resumeList">人才库</el-menu-item>
						</el-menu-item-group>
					</el-sub-menu>
					<el-sub-menu index="2">
						<template #title>
							<i class="iconfont icon-line-100"></i>
							<span class="tw-text-base">房产信息</span>
						</template>
						<el-menu-item-group>
							<el-menu-item index="2-1">中介公司</el-menu-item>
							<el-menu-item index="2-2">经纪人</el-menu-item>
							<el-menu-item index="2-3">购买套餐</el-menu-item>
						</el-menu-item-group>
					</el-sub-menu>
					<el-sub-menu index="3">
						<template #title>
							<i class="iconfont icon-jiazhengfuwu"></i>
							<span class="tw-text-base">家政服务</span>
						</template>
						<el-menu-item-group>
							<el-menu-item index="3-1">店铺配置</el-menu-item>
							<el-menu-item index="3-2">管理信息</el-menu-item>
							<el-menu-item index="3-3">发布信息</el-menu-item>
							<el-menu-item index="3-4">服务项目</el-menu-item>
							<el-menu-item index="3-5">购买套餐</el-menu-item>
						</el-menu-item-group>
					</el-sub-menu>
					<el-sub-menu index="4">
						<template #title>
							<i class="iconfont icon-hunyinjiashi"></i>
							<span class="tw-text-base">婚介交友</span>
						</template>
						<el-menu-item-group>
							<el-menu-item index="4-1">门店资料</el-menu-item>
							<el-menu-item index="4-2">门店红娘</el-menu-item>
						</el-menu-item-group>
					</el-sub-menu>
					<el-sub-menu index="5">
						<template #title>
							<i class="iconfont icon-tuipiao"></i>
							<span class="tw-text-base">财务信息</span>
						</template>
						<el-menu-item-group>
							<el-menu-item index="5-1">账户充值</el-menu-item>
							<el-menu-item index="5-2">现金与兑换</el-menu-item>
							<el-menu-item index="5-3">交易记录</el-menu-item>
							<el-menu-item index="5-4">积分转赠</el-menu-item>
							<el-menu-item index="5-5">积分记录</el-menu-item>
							<el-menu-item index="5-6">订单明细</el-menu-item>
						</el-menu-item-group>
					</el-sub-menu>
					<el-sub-menu index="6">
						<template #title>
							<i class="iconfont icon-aixin"></i>
							<span class="tw-text-base">客户服务</span>
						</template>
						<el-menu-item-group>
							<el-menu-item index="6-1">模块管理</el-menu-item>
							<el-menu-item index="6-2">保障金</el-menu-item>
							<el-menu-item index="6-3">精准推广</el-menu-item>
						</el-menu-item-group>
					</el-sub-menu>
				</el-menu>
			</div>
			<div class="userCenter">
				<div class="userCenter-con tw-p-8">
					<router-view />
				</div>
				<div class="userCenter-bot tw-p-6 tw-bg-white tw-text-center">
					<div class="tw-flex tw-items-center tw-justify-center">
						<div class="tw-cursor-pointer tw-px-4 tw-text-gray-400 tw-border-r tw-border-gray-100">关于我们</div>
						<div class="tw-cursor-pointer tw-px-4 tw-text-gray-400 tw-border-r tw-border-gray-100">关于我们</div>
						<div class="tw-cursor-pointer tw-px-4 tw-text-gray-400 tw-border-r tw-border-gray-100">关于我们</div>
						<div class="tw-cursor-pointer tw-px-4 tw-text-gray-400">关于我们</div>
					</div>
					<div class="tw-text-gray-400 tw-mt-2">Copyright © 2022 58.com 版权所有</div>
				</div>
			</div>
		</div>
	</main>
</template>

<script>
export default {
	name: "BusinessLayout"
}
</script>

<style scoped lang="scss">
.userCenter-con {
	min-height: calc(100vh - 156px);
	background-color: #F5F5FA;
	position: relative;
}

.userCenter {
	padding-left: 200px;
	height: 100%;
}

.head {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	box-shadow: 0 4px 12px 0 rgba(14, 21, 42, .1);
	z-index: 99;
}

.el-menu {
	border: none;
}

.el-menu-item:hover {
	background-color: #505563;
}

::v-deep .el-sub-menu__title:hover {
	background-color: #505563 !important;
}

.el-menu-item.is-active {
	background-color: #505563;
	border-left: 4px solid #ef4444;
}

.userMenu {
	background: #383d4a;
	position: fixed;
	height: 100%;
	left: 0;
	top: 0;
	padding-top: 88px;
	width: 200px;
}

.userMenu-tit {
	background: #fafafa;
	padding-top: 24px;

	span {
		margin: 0 20px;
		font-size: 18px;
		font-weight: 700;
		color: #666;
		border-bottom: 1px solid #eaeaea;
		padding-bottom: 12px;
		display: block;
	}
}

.userMenu {
	.iconfont {
		font-size: 18px;
		margin-right: 12px;
	}
}

.userNav {
	.userNav-item {
		font-size: 16px;
		color: #fff;
		margin-right: 24px;
	}

	.active {
		font-weight: 600;
		font-size: 18px;
	}
}

.searchBox {
	display: flex;
	height: 32px;

	.searchBox-btn {
		width: 60px;
		height: 32px;
		border: 1px solid #ef4444;
		text-align: center;
	}

	.searchBox-input {
		width: 300px;
		height: 32px;
		border: 1px solid #ef4444;
		outline: none;
	}
}

.publish-btn {
	height: 32px;
	border: 1px solid #ef4444;
	border-radius: 16px;
	padding: 0 16px;
	color: #ef4444;
	margin-left: 24px;
}
</style>