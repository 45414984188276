import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { store } from './store'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import './index.css'
import './assets/font/iconfont.css'
import './element-variables.scss'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
// 腾讯IM
import { TUIComponents, TUICore, genTestUserSig } from './TUIKit';
// import TUICallKit
import { TUICallKit } from '@tencentcloud/call-uikit-vue';

const SDKAppID = 1600010811; // Your SDKAppID
const secretKey = 'aa945a3fb0fad100916118a9c9ef13d74fa6810f3b39903d5d11b42a7e0ea535'; //Your secretKey
const userID = 'administrator'; // User ID


// init TUIKit
const TUIKit = TUICore.init({
    SDKAppID,
});
// TUIKit add TUIComponents
TUIKit.use(TUIComponents);
// TUIKit add TUICallKit
TUIKit.use(TUICallKit);



// login TUIKit
// TUIKit.login({
//     userID: userID,
//     userSig: genTestUserSig({
//         SDKAppID,
//         secretKey,
//         userID,
//     }).userSig, // The password with which the user logs in to IM. It is the ciphertext generated by encrypting information such as userID.For the detailed generation method, see Generating UserSig
// });



const app = createApp(App)

// app.config.globalProperties.$uikit = null
app.config.globalProperties.$TUIKit = TUIKit

// 全局注册el-icon
for (const [name, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(name, component);
}


// .use(TUIKit)
app.use(router).use(TUIKit).use(ElementPlus).mount('#app')

export { app };