<template>
  <main class="headbg">
    <header class="head">
      <div class="
                      tw-h-28 tw-flex tw-justify-between tw-items-center
                      w-1200
                      head-main
                    ">
        <a href=""><img src="@/assets/img/logo.png" alt="" /></a>
        <div class="tw-h-12 searchBox">
          <input class="searchBox-input tw-text-sm tw-px-4 tw-rounded-l-2xl" placeholder="请输入内容" />
          <button class="
                          tw-text-white tw-bg-red-500
                          searchBox-button
                          tw-rounded-r-2xl
                        ">
            <i class="iconfont icon-sousuo"></i><span class="tw-ml-1 tw-text-sm">搜索</span>
          </button>
        </div>
        <button class="tw-h-12 tw-bg-red-500 tw-rounded-2xl tw-text-white tw-w-40">
          <i class="iconfont icon-icon_fabu"></i><span class="tw-ml-1 tw-text-sm">免费发布信息</span>
        </button>
      </div>
    </header>
    <!-- <div class="tw-bg-cover tw-bg-center" :style="{backgroundImage:'url('+require('@/assets/img/headbg.png')+')'}"></div> -->
    <div class="w-1200">
      <ul class="homeNav tw-h-10 tw-flex">
        <li><a class="active" href="">首页</a></li>
        <li>
          <a href="">中意WAP <i class="iconfont icon-arrow-down"></i></a>
        </li>
        <li>
          <a href="">中意APP <i class="iconfont icon-arrow-down"></i></a>
        </li>
        <li>
          <a href="">中意公众号 <i class="iconfont icon-arrow-down"></i></a>
        </li>
        <li>
          <a href="">中意小程序 <i class="iconfont icon-arrow-down"></i></a>
        </li>
        <li><a href="">渠道招商 </a></li>
        <li><a href="">中意金桥简介 </a></li>
      </ul>
      <div class="tw-bg-white tw-rounded-b-xl tw-flex tw-justify-between">
        <div class="icoNav tw-flex tw-items-center tw-justify-between">
          <div class="icoNav-item">
            <div class="icoNav-img tw-cursor-pointer" @click="$router.push('/user/job/index')">
              <img src="@/assets/img/icon-1.png" alt="" />
              <p>招聘</p>
            </div>
            <div class="icoNav-link">
              <a href="">全职</a>
              <span>&nbsp;/&nbsp;</span>
              <a href="">兼职</a>
              <span>&nbsp;/&nbsp;</span>
              <a href="">简历</a>
            </div>
          </div>
          <div class="icoNav-item">
            <div class="icoNav-img tw-cursor-pointer" href="">
              <img src="@/assets/img/icon-2.png" alt="" />
              <p>房产</p>
            </div>
            <div class="icoNav-link">
              <a href="">租房</a><span>&nbsp;/&nbsp;</span><a href="">二手房</a><span>&nbsp;/&nbsp;</span><a href="">新房</a>
            </div>
          </div>
          <div class="icoNav-item">
            <a class="icoNav-img" href=""><img src="@/assets/img/icon-3.png" alt="" />
              <p>家政</p>
            </a>
            <div class="icoNav-link">
              <a href="">保姆</a><span>&nbsp;/&nbsp;</span><a href="">钟点工</a><span>&nbsp;/&nbsp;</span><a href="">月嫂</a>
            </div>
          </div>
          <div class="icoNav-item">
            <a class="icoNav-img" href=""><img src="@/assets/img/icon-4.png" alt="" />
              <p>交友</p>
            </a>
            <div class="icoNav-link">
              <a href="">缘分</a><span>&nbsp;/&nbsp;</span><a href="">活动</a><span>&nbsp;/&nbsp;</span><a href="">故事</a>
            </div>
          </div>
          <div class="icoNav-item">
            <a class="icoNav-img" href=""><img src="@/assets/img/icon-5.png" alt="" />
              <p>现场招聘会</p>
            </a>
            <div class="icoNav-link">
              <a href="">企业</a><span>&nbsp;/&nbsp;</span><a href="">直聘</a>
            </div>
          </div>
          <div class="icoNav-item">
            <a class="icoNav-img" href=""><img src="@/assets/img/icon-6.png" alt="" />
              <p>实体人才市场</p>
            </a>
            <div class="icoNav-link">
              <a href="">专家</a><span>&nbsp;/&nbsp;</span><a href="">热招</a>
            </div>
          </div>
          <div class="icoNav-item">
            <a class="icoNav-img" href=""><img src="@/assets/img/icon-7.png" alt="" />
              <p>中意金桥</p>
            </a>
            <div class="icoNav-link"><a href="">内部招聘</a></div>
          </div>
        </div>
        <dl class="homeCon homeCon-icon">
          <dt>
            <h3><a href="">到家服务</a></h3>
            <span><a href="">快速找到优质月嫂保姆</a></span>
          </dt>
          <dd>
            <a class="homeCon-icon-item">
              <i class="iconfont icon-work"></i>
              <span>家庭保洁</span>
            </a>
            <a class="homeCon-icon-item">
              <i class="iconfont icon-work"></i>
              <span>家庭保洁</span>
            </a>
            <a class="homeCon-icon-item">
              <i class="iconfont icon-work"></i>
              <span>家庭保洁</span>
            </a>
            <a class="homeCon-icon-item">
              <i class="iconfont icon-work"></i>
              <span>家庭保洁</span>
            </a>
            <a class="homeCon-icon-item">
              <i class="iconfont icon-work"></i>
              <span>家庭保洁</span>
            </a>
          </dd>
        </dl>
      </div>
      <div class="tw-flex tw-justify-between tw-mt-4">
        <div class="homeCol-1 tw-h-auto">
          <dl class="homeCon homeCon-link">
            <dt>
              <h3><a href="">荆州房产</a></h3>
              <span><a href=""></a></span>
            </dt>
            <dd>
              <div class="tw-w-3/12"><a href="">新房</a></div>
              <div class="tw-w-9/12">
                <a href="">本月开盘</a>
                <span>&nbsp;/&nbsp;</span>
                <a href="">热销楼盘</a>
                <span>&nbsp;/&nbsp;</span>
                <a href="">推荐楼盘</a>
              </div>
            </dd>
            <dd>
              <div class="tw-w-3/12"><a href="">二手房</a></div>
              <div class="tw-w-9/12">
                <a href="">房源</a>
                <span>&nbsp;/&nbsp;</span>
                <a href="">中介公司</a>
                <span>&nbsp;/&nbsp;</span>
                <a href="">找经纪人</a>
                <span>&nbsp;/&nbsp;</span>
                <a href="">地图找房</a>
              </div>
            </dd>

            <dd>
              <div class="tw-w-3/12"><a href="">租房</a></div>
              <div class="tw-w-9/12">
                <a href="">区域找房</a>
                <span>&nbsp;/&nbsp;</span>
                <a href="">找小区</a>
                <span>&nbsp;/&nbsp;</span>
                <a href="">地图找房</a>
              </div>
            </dd>
            <dd>
              <div class="tw-w-3/12"><a href="">写字楼</a></div>
              <div class="tw-w-9/12">
                <a href="">写字楼出租</a>
                <span>&nbsp;/&nbsp;</span>
                <a href="">写字楼出售</a>
              </div>
            </dd>
            <dd>
              <div class="tw-w-3/12"><a href="">商铺</a></div>
              <div class="tw-w-9/12">
                <a href="">商铺出租</a>
                <span>&nbsp;/&nbsp;</span>
                <a href="">商铺出售</a>
                <span>&nbsp;/&nbsp;</span>
                <a href="">商铺转让</a>
              </div>
            </dd>
            <dd>
              <div class="tw-w-3/12"><a href="">厂房/仓库</a></div>
              <div class="tw-w-9/12">
                <a href="">厂房出租</a>
                <span>&nbsp;/&nbsp;</span>
                <a href="">厂房出售</a>
                <span>&nbsp;/&nbsp;</span>
                <a href="">厂房转让</a>
              </div>
            </dd>
          </dl>
          <!-- <dl class="homeCon homeCon-link">
            <dt>
              <h3><a href="">荆州兼职</a></h3>
              <span><a href=""></a></span>
            </dt>
            <dd v-for="item in partJobList" :key="item.id">
              <div class="tw-w-3/12">
                <a href="">{{ !item.abb ? item.name : item.abb }}</a>
              </div>
              <div class="tw-w-9/12">
                <template v-for="(item2, index) in item.children" :key="item2.id">
                  <a href="">{{ !item2.abb ? item2.name : item2.abb }}</a>
                  <span v-if="index != item.children.length - 1">&nbsp;/&nbsp;</span>
                </template>
              </div>
            </dd>
          </dl> -->
          <dl class="homeCon homeCon-link">
            <dt>
              <h3><a href="">热门行业</a></h3>
              <span><a href=""></a></span>
            </dt>
            <dd>
              <div class="tw-w-6/12">
                <a href="">互联网</a>
                <span>&nbsp;/&nbsp;</span>
                <a href="">电子商务</a>
              </div>
              <div class="tw-w-6/12">
                <a href="">其他行业</a>
              </div>
            </dd>
            <dd>
              <div class="tw-w-6/12">
                <a href="">计算机软件</a>
              </div>
              <div class="tw-w-6/12">
                <a href="">计算机硬件</a>
              </div>
            </dd>
          </dl>
        </div>
        <div class="homeCol-2 tw-h-auto">
          <dl class="homeCon homeCon-link">
            <dt>
              <h3><a href="">荆州招聘</a></h3>
              <span><a href=""></a></span>
            </dt>
            <dd class="tw-flex tw-flex-wrap">
              <div class="tw-w-4/12" v-for="item in jobTagList" :key="item.id">
                <a href="">{{ item.name }}</a>
              </div>
            </dd>
          </dl>
          <dl class="homeCon homeCon-link">
            <dt>
              <h3><a href="">热招职位</a></h3>
              <span><a href=""></a></span>
            </dt>

            <dd v-for="item in hotList" :key="item.id">
              <div class="tw-w-3/12" @click="$router.push('/user/job/index')">
                <a class="tw-cursor-pointer">{{
                  !item.abb ? item.name : item.abb
                }}</a>
              </div>
              <div class="tw-w-9/12">
                <a v-for="(item2, index) in item.children" :key="item2.id" class="tw-cursor-pointer"
                  @click="$router.push({ path: '/user/job/list', query: { jobCategoryId: item2.id, jobCategoryName: item2.name } })">{{
                    !item2.abb ?
                    item2.name : item2.abb
                  }}<span v-if="index != item.children.length - 1">&nbsp;/&nbsp;</span></a>
              </div>
            </dd>
          </dl>
          <dl class="homeCon homeCon-link">
            <dt>
              <h3><a href="javascript:;">求职简历</a></h3>
              <span><a href="javascript:;"></a></span>
            </dt>
            <template v-for="index in hotWantedList.length" :key="'hotWanted_' + index">
              <dd v-if="index % 4 == 1">
                <template v-for="(item, idx) in hotWantedList" :key="item.id">
                  <div class="tw-w-3/12" v-if="idx >= index - 1 && idx < index + 3"
                    @click="$router.push({ path: '/business/job/resumeList', query: { jobCategoryId: item.id } })">
                    <a href="javascript:;">{{ !item.abb ? item.name : item.abb }}</a>
                  </div>
                </template>
              </dd>
            </template>
          </dl>
        </div>
        <div class="homeCol-3 tw-h-auto">
          <dl class="homeCon homeCon-link">
            <dt>
              <h3><a href="">家政服务</a></h3>
              <span><a href=""></a></span>
            </dt>
            <dd>
              <div class="tw-w-3/12"><a href="">搬家</a></div>
              <div class="tw-w-9/12">
                <a href="">公司搬家</a>
                <span>&nbsp;/&nbsp;</span>
                <a href="">居民搬家</a>
                <span>&nbsp;/&nbsp;</span>
                <a href="">长途搬运</a>
              </div>
            </dd>
            <dd>
              <div class="tw-w-3/12"><a href="">搬家</a></div>
              <div class="tw-w-9/12">
                <a href="">公司搬家</a>
                <span>&nbsp;/&nbsp;</span>
                <a href="">居民搬家</a>
                <span>&nbsp;/&nbsp;</span>
                <a href="">长途搬运</a>
              </div>
            </dd>
            <dd>
              <div class="tw-w-3/12"><a href="">搬家</a></div>
              <div class="tw-w-9/12">
                <a href="">公司搬家</a>
                <span>&nbsp;/&nbsp;</span>
                <a href="">居民搬家</a>
                <span>&nbsp;/&nbsp;</span>
                <a href="">长途搬运</a>
              </div>
            </dd>
            <dd>
              <div class="tw-w-3/12"><a href="">搬家</a></div>
              <div class="tw-w-9/12">
                <a href="">公司搬家</a>
                <span>&nbsp;/&nbsp;</span>
                <a href="">居民搬家</a>
                <span>&nbsp;/&nbsp;</span>
                <a href="">长途搬运</a>
              </div>
            </dd>
            <dd>
              <div class="tw-w-3/12"><a href="">搬家</a></div>
              <div class="tw-w-9/12">
                <a href="">公司搬家</a>
                <span>&nbsp;/&nbsp;</span>
                <a href="">居民搬家</a>
                <span>&nbsp;/&nbsp;</span>
                <a href="">长途搬运</a>
              </div>
            </dd>
          </dl>
          <dl class="homeCon homeCon-link">
            <dt>
              <h3><a href="">招才直聘</a></h3>
              <span><a href=""></a></span>
            </dt>
            <dd>
              <div class="tw-w-1/5"><a href="">新房</a></div>
              <div class="tw-w-1/5"><a href="">新房</a></div>
              <div class="tw-w-1/5"><a href="">新房</a></div>
              <div class="tw-w-1/5"><a href="">新房</a></div>
              <div class="tw-w-1/5"><a href="">新房</a></div>
            </dd>
            <dd>
              <div class="tw-w-1/5"><a href="">新房</a></div>
              <div class="tw-w-1/5"><a href="">新房</a></div>
              <div class="tw-w-1/5"><a href="">新房</a></div>
              <div class="tw-w-1/5"><a href="">新房</a></div>
              <div class="tw-w-1/5"><a href="">新房</a></div>
            </dd>
          </dl>
          <dl class="homeCon homeCon-link">
            <dt>
              <h3><a href="">征婚交友</a></h3>
              <span><a href=""></a></span>
            </dt>
            <dd>
              <div class="tw-w-3/12"><a href="">新房</a></div>
              <div class="tw-w-9/12">
                <a href="">全职</a>
                <span>&nbsp;/&nbsp;</span>
                <a href="">兼职</a>
                <span>&nbsp;/&nbsp;</span>
                <a href="">简历</a>
              </div>
            </dd>
            <dd>
              <div class="tw-w-3/12"><a href="">新房</a></div>
              <div class="tw-w-9/12">
                <a href="">全职</a>
                <span>&nbsp;/&nbsp;</span>
                <a href="">兼职</a>
                <span>&nbsp;/&nbsp;</span>
                <a href="">简历</a>
              </div>
            </dd>
            <dd>
              <div class="tw-w-3/12"><a href="">新房</a></div>
              <div class="tw-w-9/12">
                <a href="">全职</a>
                <span>&nbsp;/&nbsp;</span>
                <a href="">兼职</a>
                <span>&nbsp;/&nbsp;</span>
                <a href="">简历</a>
              </div>
            </dd>
            <dd>
              <div class="tw-w-3/12"><a href="">新房</a></div>
              <div class="tw-w-9/12">
                <a href="">全职</a>
                <span>&nbsp;/&nbsp;</span>
                <a href="">兼职</a>
                <span>&nbsp;/&nbsp;</span>
                <a href="">简历</a>
              </div>
            </dd>
          </dl>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { getJobCategoryList, getJobTagList } from "@/utils/api";

export default {
  name: "Index",
  data() {
    return {
      input: "",
      jobTagList: [],
      hotList: [],
      hotWantedList: [],
      partJobList: [],
      //loading: false
    };
  },
  methods: {
    getCategoryList() {
      getJobCategoryList({
        type: 1,
      }).then((res) => {
        if (res.code === 0) {
          this.hotList = res.data.hotList;
          this.hotWantedList = res.data.hotWantedList;
          this.partJobList = res.data.partJobList;
          //this.loading = true
        }
      });
    },
    getJobTagList() {
      getJobTagList().then((res) => {
        if (res.code === 0) {
          this.jobTagList = res.data;
        }
      });
    },
  },
  mounted() {
    this.getCategoryList();
    this.getJobTagList();
  },
};
</script>
<style>
body {
  background-color: #fff9f9;
}
</style>
<style scoped lang="scss">
.headbg {
  background: url(@/assets/img/headbg.png) no-repeat;
  background-size: 100%;
  min-height: 336px;
  height: auto;
}

.searchBox {
  display: flex;
  width: 560px;

  .searchBox-button {
    width: 100px;
  }

  .searchBox-input {
    flex: 1;
    height: 48px;
    border: none;
    outline: none;

    &:focus {
      border: 2px solid #ef4444;
    }
  }
}

.homeNav {
  border-bottom: 2px solid #ef4444;
  background-color: #fff;
  border-radius: 12px 12px 0 0;
}

.homeNav li a {
  margin-left: 10px;
  padding: 0 32px;
  line-height: 40px;
  height: 40px;
  font-size: 15px;
  display: block;
  color: #ef4444;
  font-weight: bold;
}

.homeNav li a:hover {
  background-color: #ef4444;
  color: #fff;
  border-radius: 12px 12px 0 0;
}

.homeNav li a.active {
  margin-left: 0;
  background-color: #ef4444;
  color: #fff;
  border-radius: 12px 12px 0 0;
}

.homeNav i {
  font-size: 12px;
}

.icoNav {
  padding: 16px 0;
}

.icoNav-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 120px;
  border-right: 1px solid #f8f8f8;
}

.icoNav-img {
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    height: 42px;
    width: 42px;
  }

  p {
    font-size: 14px;
    color: #555;
    margin-top: 4px;

    &:hover {
      color: #ef4444;
    }
  }
}

.icoNav-link {
  margin-top: 4px;

  a {
    font-size: 12px;
    color: #ef4444;
  }

  span {
    color: #eee;
  }
}

.homeCon {
  padding: 0 16px;
  background-color: #fff;
  border-radius: 16px;

  dt {
    height: 42px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #f8f8f8;

    h3 {
      font-weight: bold;
      font-size: 16px;
      color: #ef4444;
    }

    span {
      font-size: 12px;
      color: #ef4444;
      margin-left: 8px;
    }
  }

  dd {}
}

.homeCon-icon {
  width: 360px;

  dd {
    display: flex;
    flex-wrap: wrap;
    padding: 10px 0;
  }

  .homeCon-icon-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 64px;

    i {
      font-size: 36px;
      color: #ef4444;
      line-height: 36px;
    }
  }
}

.homeCon-link {
  margin-bottom: 16px;
  padding-bottom: 8px;

  dt {
    margin-bottom: 4px;
  }

  dd {
    display: flex;

    a {
      font-size: 14px;
      line-height: 30px;

      &:hover {
        color: #ef4444;
      }
    }

    span {
      color: #dee3e7;
    }
  }
}

.homeCol-1 {
  width: 404px;
}

.homeCol-2 {
  width: 404px;
}

.homeCol-3 {
  width: 360px;
}
</style>
