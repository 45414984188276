<template>
  <el-config-provider :locale="locale">
    <div id="app-root">
      <router-view />
    </div>
  </el-config-provider>
</template>

<script>
import { defineComponent } from "vue";
import { ElConfigProvider } from "element-plus";
import zhCn from 'element-plus/dist/locale/zh-cn.mjs' //国际化，配置此项目语言环境为中文
import { autoLogin, getUserSig } from '@/utils/api'
import { app } from "./main";

export default defineComponent({
  components: {
    ElConfigProvider,
  },
  setup() {
    return {
      locale: zhCn,
    };
  },
  mounted() {
    const user = JSON.parse(localStorage.getItem("user"))
    const isLogin = localStorage.getItem("isLogin")
    console.log("user=" + user)
    if (isLogin && user?.id) {
      this.autoLogin(user.id)
    }
  },
  methods: {
    autoLogin(userId) {
      autoLogin({
        userId
      }).then((res) => {
        if (res.code === 0) {
          console.log(res)
          localStorage.setItem("token", res.data.token);
          localStorage.setItem("expire", res.data.expire);
          localStorage.setItem("isLogin", "true");
          this.initIM(res.data.user)
        }
      });
    },
    initIM(user) {
      // const initOptions = {
      //   appkey: "4a6e7b8b3b706409b63d1dd5b9f3723b", // 请填写你的appkey
      //   account: user.id, // 请填写你的account
      //   token: user.imToken, // 请填写你的token
      // };
      // app.config.globalProperties.$uikit = new IMUIKit({
      //   initOptions,
      //   singleton: true,
      //   sdkVersion: 1,
      // });
      console.log(user)
      getUserSig({
        userId: user.id
      }).then((res) => {
        if (res.code === 0) {
          console.log(res)
          app.config.globalProperties.$TUIKit.login({
            userID: user.id,
            userSig: res.data, // The password with which the user logs in to IM. It is the ciphertext generated by encrypting information such as userID.For the detailed generation method, see Generating UserSig
          });
        }
      });

    }
  }
});
</script>


<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #222;
}

body {
  font: 12px/1.2 "Hiragino Sans GB", "Microsoft YaHei", "微软雅黑", arial,
    Tahoma, SimSun, sans-serif;
  color: #333;
  min-width: 1190px;
  text-rendering: geometricPrecision;
  -webkit-font-smoothing: antialiased;
  // _background-image: url(about:blank);
  _background-attachment: fixed;
  background-color: #fff9f9;
}

.w-1200 {
  width: 1200px;
  margin: 0 auto;
}
</style>
