import UserLayout from "@/views/User/User/Layout/UserLayout";

const routes = [{
        path: '/user',
        component: UserLayout,
        redirect: '/user/index',
        children: [{
                path: '/user/index',
                component: () =>
                    import ('@/views/User/User/Index')
            },
            {
                path: '/user/funds',
                component: () =>
                    import ('@/views/User/User/Funds')
            },
            {
                path: '/user/merchant',
                component: () =>
                    import ('@/views/User/User/Merchant')
            },
            {
                path: '/user/setting',
                component: () =>
                    import ('@/views/User/User/Setting')
            },
            {
                path: '/user/collect/collectMy',
                component: () =>
                    import ('@/views/User/User/Collect/CollectMy')
            },
            {
                path: '/user/collect/myCollect',
                component: () =>
                    import ('@/views/User/User/Collect/MyCollect')
            },
            {
                path: '/user/browse',
                component: () =>
                    import ('@/views/User/User/Browse')
            },
            {
                path: '/user/jobWanted/resumeDownHis',
                component: () =>
                    import ('@/views/User/User/JobWanted/ResumeDownHis')
            },
            {
                path: '/user/jobWanted/index',
                component: () =>
                    import ('@/views/User/User/JobWanted/Index.vue')
            },
            {
                path: '/user/jobWanted/deliveryRecord',
                component: () =>
                    import ('@/views/User/User/JobWanted/DeliveryRecord')
            },
            {
                path: '/user/jobWanted/myResume',
                component: () =>
                    import ('@/views/User/User/JobWanted/MyResume')
            },
            {
                path: '/user/jobWanted/complaint',
                component: () =>
                    import ('@/views/User/User/JobWanted/Complaint')
            },
            {
                path: '/user/jobWanted/privacy',
                component: () =>
                    import ('@/views/User/User/JobWanted/Privacy')
            },
            {
                path: '/user/jobWanted/interviewinvited',
                component: () =>
                    import ('@/views/User/User/JobWanted/Interviewinvited')
            }
        ]
    },

]

export default routes