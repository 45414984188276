import ResumeLayout from "@/views/User/Resume/Layout/ResumeLayout";

const routes = [{
        path: '/user/resume',
        component: ResumeLayout,
        // redirect: '/user/job/index',
        redirect: '/user/resume/index',
        children: [
            {
                path: '/user/resume/detail',
                component: () =>
                    import ('@/views/User/Resume/Detail')
            },
            {
                path: '/user/resume/add',
                component: () =>
                    import ('@/views/User/Resume/Add')
            },
            {
                path: '/user/resume/perfect',
                component: () =>
                    import ('@/views/User/Resume/Perfect')
            }
        ]
    },

]

export default routes