<template>
	<main class="tw-bg-white tw-pb-6">
		<header class="head">
			<div class="tw-bg-red-500">
				<div class="tw-flex tw-items-center tw-justify-between w-1200 tw-h-20">
					<div class="tw-cursor-pointer tw-flex tw-items-center" @click="$router.push('/index')">
						<img src="@/assets/img/logo2.png" alt="" height="50px">
					</div>
					<div class="tw-flex userNav tw-items-center">
						<div class="userNav-item tw-cursor-pointer" @click="$router.push('/user/index')">个人中心</div>
						<div class="userNav-item tw-cursor-pointer" @click="$router.push('/business/job/index')">商家中心</div>
						<div class="userNav-item tw-cursor-pointer" @click="$router.push('/user/setting')">账户设置</div>
						<div class="userNav-item tw-cursor-pointer active">我的资金</div>
					</div>
					<div class="tw-flex">
						<div class="searchBox">
							<input class="searchBox-input tw-text-sm tw-px-4 tw-rounded-l-2xl" placeholder="请输入内容" />
							<button class="searchBox-btn tw-text-white tw-bg-red-500 tw-rounded-r-2xl">
								<i class="iconfont icon-sousuo"></i>
							</button>
						</div>
						<button class="publish-btn">
							<span class="tw-ml-1 tw-text-sm">免费发布信息</span>
						</button>
					</div>
				</div>
			</div>
		</header>
		<div class="tw-mt-6 w-1200">
			<el-row class="userMain" :gutter="40">
				<el-col :span="5">
					<div class="userMenu">
						<div class="userMenu-tit">
							<span>个人中心</span>
						</div>
						<el-menu router :default-active="$route.path" class="el-menu-vertical-demo"
							background-color="#fafafa">
							<el-menu-item index="/user/index">
								<i class="iconfont icon-icon_fabu"></i>
								<span class="tw-text-base">我的发布</span>
							</el-menu-item>
							<el-sub-menu index="2">
								<template #title>
									<i class="iconfont icon-shoucang1"></i>
									<span class="tw-text-base">我的收藏</span>
								</template>
								<el-menu-item-group>
									<el-menu-item index="/user/collect/myCollect">我的收藏信息</el-menu-item>
									<el-menu-item index="/user/collect/CollectMy">谁收藏了我</el-menu-item>
								</el-menu-item-group>
							</el-sub-menu>
							<el-menu-item index="/user/browse">
								<i class="iconfont icon-dingdan"></i>
								<span class="tw-text-base">我的浏览</span>
							</el-menu-item>
							<el-sub-menu index="4">
								<template #title>
									<!-- <i class="iconfont icon-work"></i> -->
									<el-icon>
										<Box />
									</el-icon>
									<span class="tw-text-base">我的求职</span>
								</template>
								<el-menu-item-group>
									<el-menu-item index="/user/jobWanted/index">求职首页</el-menu-item>
									<el-menu-item index="/user/jobWanted/myResume">我的简历</el-menu-item>
									<el-menu-item index="/user/jobWanted/deliveryRecord">简历投递记录</el-menu-item>
									<el-menu-item index="/user/jobWanted/resumeDownHis">谁下载了我的简历</el-menu-item>
									<el-menu-item index="/user/jobWanted/interviewinvited">面试邀请</el-menu-item>
									<el-menu-item index="/user/jobWanted/privacy">隐私设置</el-menu-item>
									<el-menu-item index="/user/jobWanted/complaint">投诉管理</el-menu-item>
								</el-menu-item-group>
							</el-sub-menu>
							<el-sub-menu index="5">
								<template #title>
									<i class="iconfont icon-zhaopinwangzhan"></i>
									<span class="tw-text-base">我的招聘</span>
								</template>
								<el-menu-item-group>
									<el-menu-item index="/business/job/position">职位管理</el-menu-item>
									<el-menu-item index="/business/job/resumeList">简历管理</el-menu-item>
									<el-menu-item index="5-3">简历套餐</el-menu-item>
									<el-menu-item index="5-4">成为会员</el-menu-item>
									<el-menu-item index="5-5">被投诉管理</el-menu-item>
									<el-menu-item index="5-6">账号管理</el-menu-item>
								</el-menu-item-group>
							</el-sub-menu>
							<el-sub-menu index="6">
								<template #title>
									<i class="iconfont icon-line-100"></i>
									<span class="tw-text-base">我的房源</span>
								</template>
								<el-menu-item-group>
									<el-menu-item index="6-1">我的房源</el-menu-item>
								</el-menu-item-group>
							</el-sub-menu>
							<el-sub-menu index="7">
								<template #title>
									<i class="iconfont icon-jiazhengfuwu"></i>
									<span class="tw-text-base">我的家政</span>
								</template>
								<el-menu-item-group>
									<el-menu-item index="7-1">我的家政</el-menu-item>
								</el-menu-item-group>
							</el-sub-menu>
							<el-sub-menu index="8">
								<template #title>
									<i class="iconfont icon-hunyinjiashi"></i>
									<span class="tw-text-base">我的婚介</span>
								</template>
								<el-menu-item-group>
									<el-menu-item index="8-1">我的婚介</el-menu-item>
								</el-menu-item-group>
							</el-sub-menu>
							<el-sub-menu index="9">
								<template #title>
									<i class="iconfont icon-aixin"></i>
									<span class="tw-text-base">客户服务</span>
								</template>
								<el-menu-item-group>
									<el-menu-item index="9-1">我的反欺诈</el-menu-item>
									<el-menu-item index="9-2">处罚申诉</el-menu-item>
									<el-menu-item index="9-3">违规记录</el-menu-item>
									<el-menu-item index="9-4">举报中心</el-menu-item>
								</el-menu-item-group>
							</el-sub-menu>
							<el-menu-item index="10">
								<i class="iconfont icon-bangzhu"></i>
								<span class="tw-text-base">帮助中心</span>
							</el-menu-item>
						</el-menu>
					</div>
				</el-col>
				<el-col :span="19">
					<router-view />
				</el-col>
			</el-row>
		</div>
	</main>
</template>

<script>
export default {
	name: "UserLayout"
}
</script>

<style scoped lang="scss">
.el-menu {
	border: none;
}

.el-menu-item:hover {
	background-color: #FEF2F2;
}

::v-deep .el-sub-menu__title:hover {
	background-color: #FEF2F2 !important;
}

.userMenu-tit {
	background: #fafafa;
	padding-top: 24px;

	span {
		margin: 0 20px;
		font-size: 18px;
		font-weight: 700;
		color: #666;
		border-bottom: 1px solid #eaeaea;
		padding-bottom: 12px;
		display: block;
	}
}

.userMenu {
	.iconfont {
		font-size: 18px;
		margin-right: 12px;
	}
}

.userNav {
	.userNav-item {
		font-size: 16px;
		color: #fff;
		margin-right: 24px;
	}

	.active {
		font-weight: 600;
		font-size: 18px;
	}
}

.searchBox {
	display: flex;
	height: 30px;
	margin-left: 30px;

	.searchBox-btn {
		width: 60px;
		height: 30px;
		border: 1px solid #fff;
		text-align: center;
	}

	.searchBox-input {
		width: 300px;
		height: 30px;
		border: none;
		outline: none;
	}
}

.publish-btn {
	height: 30px;
	border: 1px solid #fff;
	border-radius: 15px;
	padding: 0 16px;
	color: #fff;
	margin-left: 24px;
}
</style>
