import BusinessLayout from "@/views/Business/Layout/BusinessLayout";

const routes = [{
        path: '/business',
        component: BusinessLayout,
        redirect: '/business/index',
        children: [{
            path: '/business/index',
            component: () =>
                import ('@/views/Business/Index')
        }]
    },

]

export default routes