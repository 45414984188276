// import BusinessLayout from "@/views/Business/Layout/BusinessLayout.vue";

const routes = [{
    // path: '/admin/job',
    // component: AdminLayout,
    path: '/business/buy',
    // component: BusinessLayout,
    redirect: '/business/buy/buyResume',
    children: [{
            path: '/business/buy/buyResume',
            component: () =>
                import ('@/views/Business/Buy/BuyResume')
        }
    ]
}]

export default routes