import { createRouter, createWebHashHistory } from 'vue-router'
import HomePageLayout from "@/components/HomePageLayout";
import Index from "@/views/Index";
import user from "@/router/user";
import business from "@/router/business";

const routes = [{
    path: '/',
    component: HomePageLayout,
    redirect: '/index',
    children: [{
        path: '/index',
        name: 'index',
        component: Index
    },

    ...user,
    ...business
    ],
},
// 腾讯IM页面
{
    path: '/chat',
    name: 'chat',
    component: () => import('@/views/chat')
}
]

const router = createRouter({
    history: createWebHashHistory(),
    routes
})

export default router