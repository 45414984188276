import LoginLayout from "@/views/User/Login/Layout/LoginLayout";

const routes = [{
        path: '/user/Login',
        component: LoginLayout,
        redirect: '/user/login/login',
        children: [{
            path: '/user/login/login',
            component: () =>
                import ('@/views/User/Login/Login')
        }, ]
    },

]

export default routes