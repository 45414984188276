// import { createStore } from 'vuex'

// export default createStore({
//   state: {
//   },
//   getters: {
//   },
//   mutations: {
//   },
//   actions: {
//   },
//   modules: {
//   }
// })

//用响应式API做简单状态管理
import { reactive } from 'vue'

export const store = reactive({
    isLogin: false,
    setLogin(status) {
        this.isLogin = status
    },
    user: null,
    setUser(user) {
        this.user = user
    },
    //聊天弹窗是否显示
    imDialogVisible: false,
    setImDialogVisible(visible){
        this.imDialogVisible = visible
    }
})