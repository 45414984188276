import Job from "@/router/user/modules/Job";
import Company from "@/router/user/modules/Company";
import Login from "@/router/user/modules/Login";
import Resume from "@/router/user/modules/Resume";
import Business from "@/router/user/modules/Business";
import User from "@/router/user/modules/User";

const routes = [
    ...Job,
    ...Company,
    ...Login,
    ...Resume,
    ...Business,
    ...User
]

export default routes