import JobLayout from "@/views/User/Job/Layout/JobLayout";

const routes = [{
        // path: '/business/job',
        path: '/user/job',
        component: JobLayout,
        redirect: '/user/job/index',
        children: [{
                path: '/user/job/index',
                component: () =>
                    import ('@/views/User/Job/Index')
            },
            {
                path: '/user/job/list',
                component: () =>
                    import ('@/views/User/Job/List')
            },
            {
                path: '/user/job/detail',
                component: () =>
                    import ('@/views/User/Job/Detail')
            },
            {
                path: '/user/job/add',
                component: () =>
                    import ('@/views/User/Job/Add')
            }
        ]
    },

]

export default routes