import request from "./request";

export const userLogin = (data) => request.post('login', data) //密码登录接口

export const userCodeLogin = (data) => request.post('codeLogin', data) //验证码登录接口

export const autoLogin = (params) => request.get('autoLogin', { params }) //自动登录

export const getCode = (params) => request.get('sms/getCode', { params }) //获取短信验证码

//首页 兼职、热招、求职三个板块的数据接口
export const getJobCategoryList = (params) => request.get('job/category/list', { params })

export const getJobTagList = (params) => request.get('job/tag/list', { params })

export const getJobCategoryListByType = (params) => request.get('job/category/listByType', { params })
//获取职位属性
export const getJobAttrList = (params) => request.get('job/attr/list', { params })
export const getJobAttrListByType = (params) => request.get('job/attr/listByType', { params })

//获取当前用户是否已关联公司
export const existCompany = (params) => request.get('company/existCompany', { params })
//获取公司属性
export const getCompanyAttrList = (params) => request.get('company/attr/list', { params })
//获取区县列表
export const getDistrictList = (params) => request.get('job/area/listDistrict', { params })
//获取工作区域列表
export const getJobAreaList = (params) => request.get('job/area/list', { params })
//发布招聘
export const saveJob = (data) => request.post('job/save', data)
//修改招聘
export const updateJob = (data) => request.post('job/update', data)
//招聘列表
export const getJobList = (params) => request.get('job/list', { params })
//登录状态下获取招聘列表
export const getJobListWithLogin = (params) => request.get('job/listWithLogin', { params })
//保存简历
export const saveResume = (data) => request.post('resume/save', data)
//添加收藏
export const saveFavo = (params) => request.get('favo/save', { params })
//批量添加收藏
export const saveFavoBatch = (data) => request.post('favo/saveBatch', data)
//取消收藏
export const deleteFavo = (data) => request.post('favo/delete', data)
//获取收藏的招聘列表
export const listJobFavo = (params) => request.get('job/listFavo', { params })
//获取招聘详情
export const jobDetail = (params) => request.get('job/' + params.id, {})
//登录状态下，获取招聘详情
export const jobDetailWithLogin = (params) => request.get('job/getWithLogin/' + params.id, {})
//获取我的简历列表
export const resumeList = (params) => request.get('resume/myList', { params })
//删除简历
export const resumeDelete = (data) => request.post('resume/delete', data)
//更新简历
export const resumeUpdate = (data) => request.post('resume/update', data)
//设置默认简历
export const setDefaultResume = (params) => request.get('resume/setDefault', { params })
//获取简历详情
export const resumeDetail = (params) => request.get('resume/' + params, {})
//获取用户信息
export const userInfo = () => request.get('user/info', {})
//修改用户信息
export const updateUser = (data) => request.post('user/update', data)
// 修改求职状态
export const updateJobStatus = (data) => request.post('user/updateJobStatus', data)
//职位申请
export const applyJob = (data) => request.post('job/apply/save', data)
//简历投递列表
export const applyJobList = (params) => request.get('job/apply/page', { params })
//简历投递页各状态数量
export const getTabTotal = () => request.get('job/apply/getTabTotal', {})
//新增浏览记录
export const saveBrowse = (data) => request.post('browse/save', data)
//浏览记录分页列表
export const getBrowsePage = (params) => request.get('browse/page', { params })
//添加工作经历
export const saveWorkExperience = (data) => request.post('workExperience/save', data)
//修改工作经历
export const updateWorkExperience = (data) => request.post('workExperience/update', data)
//删除工作经历
export const deleteWorkExperience = (data) => request.post('workExperience/delete', data)
//添加教育经历
export const saveEducationExperience = (data) => request.post('educationExperience/save', data)
//修改教育经历
export const updateEducationExperience = (data) => request.post('educationExperience/update', data)
//删除教育经历
export const deleteEducationExperience = (data) => request.post('educationExperience/delete', data)
//获取职业优势列表
export const getResumeAdvantageList = (params) => request.get('resumeadvantage/list', { params })
//保存职业优势
export const saveResumeAdvantageRef = (data) => request.post('resumeadvantageref/save', data)


///商家中心
//我发布的职位列表
export const getMyList = (params) => request.get('job/myList', { params })
//我发布的职位各状态的数量
export const getMyTabTotal = (params) => request.get('job/getTabTotal', { params })
//浏览过我发布职位的求职者的默认简历
export const getBrowseMyPage = (params) => request.get('browse/myPage', { params })
//收到的简历
export const getReceiveResume = (params) => request.get('job/apply/myPage', { params })
//收到的简历数量
export const getReceiveResumeNum = (params) => request.get('job/apply/getTotal', { params })
//发布的职位类别
export const getPublishedCategoryList = (params) => request.get('job/category/getPublishedCategoryList', { params })
//标记简历, 收到的简历中的标记功能
export const markResume = (data) => request.post('job/apply/updateApplyStatus', data)
//导出简历
export const exportResume = (params) => request.get('job/apply/export', { params })
//回收简历
export const recycleResume = (data) => request.post('job/apply/recycle', data)
//人才库-简历列表
export const resumePage = (params) => request.get('resume/page', { params })
//人才库-获取职位路径
export const getJobCategoryPath = (params) => request.get('job/category/getParentPath', { params })
//下载简历
export const resumeDownSave = (params) => request.get('resumedownload/save', { params })
//获取我下载的简历列表
export const resumeDownPage = (params) => request.get('resumedownload/page', { params })
//标记简历, 下载的简历中的标记功能
export const feedbackResume = (data) => request.post('resumedownload/updateDownStatus', data)
//删除简历, 将回收状态改为true
export const recycleDownResume = (data) => request.post('resumedownload/recycle', data)
//保存面试邀请
export const saveInterview = (data) => request.post('interview/save', data)
//我邀请的面试
export const myInvitedInterviewPage = (params) => request.get('interview/page', { params })
//我邀请的面试数量
export const getInvitedInterviewTotal = (params) => request.get('interview/getTotal', { params })
//修改邀请的面试，主要是修改状态
export const updateInterview = (data) => request.post('interview/update', data)
//我收到的面试邀请
export const invitedMePage = (params) => request.get('interview/invitedMePage', { params })
//谁下载了我的简历
export const downMePage = (params) => request.get('resumedownload/downMePage', { params })
//根据名称、行业、地址创建公司
export const saveCompany = (data) => request.post('company/save', data)
//获取公司信息
export const getCompanyDetail = (params) => request.get('company/detail', { params })
//获取公司信息
export const getCompanyDetailById = (id) => request.get(`company/detail/${id}`, {})
//修改公司信息
export const updateCompany = (data) => request.post('company/update', data)
//获取公司列表
export const getCompanyPage = (params) => request.get('company/page', { params })
//获取公司统计
export const getCompanyStatics = () => request.get('company/statics')
//套餐列表
export const getProductList = (params) => request.get('baseproduct/list', { params })
//刷新职位
export const refreshJob = (params) => request.get('job/refresh', { params })
//获取刷新设置
export const getRefreshSetting = (params) => request.get('refreshsetting/getByInfoId', { params })
//保存刷新设置
export const saveRefreshSetting = (data) => request.post('refreshsetting', data)
//修改刷新设置
export const updateRefreshSetting = (data) => request.put('refreshsetting', data)
//删除刷新设置
export const deleteRefreshSetting = (id) => request.delete(`refreshsetting/${id}`)
//获取置顶套餐
export const getTopProductList = () => request.get('topproduct/list', {})
//立即置顶
export const topJob = (data) => request.post('job/top', data)
//设置精品职位
export const fineProductJob = (params) => request.get('job/fineProduct', { params })
//设置头条推广
export const headlinesJob = (data) => request.post('job/headlines', data)
//保存 黄金展位/优选投递
export const savePromotion = (data) => request.post('job/promotion/save', data)
//是否存在优选投递
export const existPromotion = (params) => request.get('job/promotion/exist', { params })
//是否存在黄金展位
export const existGold = (params) => request.get('job/gold/exist', { params })
//获取系统参数
export const getSysParam = (params) => request.get('sys/params/getValue', { params })
//获取兄弟职位类别
export const getBrotherCategory = (params) => request.get('job/category/getBrotherCategory', { params })
//获取推广列表
export const getPromotionList = (params) => request.get('job/promotion/list', { params })
//获取推广列表
export const updatePromotion = (data) => request.post('job/promotion/update', data)
//批量暂停推广
export const pausePromotion = (data) => request.post('job/promotion/pause', data)
//批量开启推广
export const startPromotion = (data) => request.post('job/promotion/start', data)
//批量删除推广
export const deletePromotion = (data) => request.post('job/promotion/delete', data)
//批量修改结束时间
export const updatePromotionEndTime = (data) => request.post('job/promotion/updateEndTime', data)

//网易云信即时通信添加好友，弃用
export const addFriend = (faccid) => request.post(`im/addFriend/${faccid}`)

//获取腾讯IM用户密码
export const getUserSig = (params) => request.get('userSig/getUserSig', { params })

//腾讯即时通信添加好友
export const addFriendTx = (toAccount) => request.post(`im/addFriendTx/${toAccount}`)

// 置顶预支付
export const topPrepay = (data) => request.post(`wx/top/prepay`, data)

//删除图片
export const deleteImg = (data) => request.post(`sys/oss/delete/${data.id}`)