// import AdminLayout from "@/views/Admin/Layout/AdminLayout.vue";
import BusinessLayout from "@/views/Business/Layout/BusinessLayout.vue";

const routes = [{
    // path: '/admin/job',
    // component: AdminLayout,
    path: '/business/job',
    component: BusinessLayout,
    redirect: '/business/job/index',
    children: [{
            path: '/business/job/index',
            component: () =>
                import ('@/views/Business/Index')
        },
        {
            path: '/business/job/position',
            /*职位管理*/
            component: () =>
                import ('@/views/Business/Job/Position')
        },
        {
            path: '/business/job/interestedInMe',
            /*对我感兴趣的*/
            component: () =>
                import ('@/views/Business/Job/InterestedInMe')
        },
        {
            path: '/business/job/resumeReceive',
            /*收到的简历*/
            component: () =>
                import ('@/views/Business/Job/ResumeReceive')
        },
        {
            path: '/business/job/interviewRecord',
            /*面试记录*/
            component: () =>
                import ('@/views/Business/Job/InterviewRecord')
        },
        {
            path: '/business/job/resumeDown',
            /*下载的简历*/
            component: () =>
                import ('@/views/Business/Job/ResumeDown')
        },
        {
            path: '/business/job/resumeList',
            /*人才库-简历列表*/
            component: () =>
                import ('@/views/Business/Job/ResumeList')
        },
        {
            path: '/business/job/resumeDetail',
            /*建立详情*/
            component: () =>
                import ('@/views/Business/Job/ResumeDetail')
        },
        {
            path: '/business/job/companyInfo',
            /*公司信息*/
            component: () =>
                import ('@/views/Business/Job/CompanyInfo')
        },
        {
            path: '/business/job/promotion',
            /*推广管理*/
            component: () =>
                import ('@/views/Business/Job/Promotion')
        },
    ]
}]

export default routes