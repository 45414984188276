<template>
  <div>
    <div class="tw-bg-white head-top">
      <div class="w-1200 tw-h-8 tw-flex tw-justify-between tw-items-center">
        <div class="tw-flex">
          <div class="tw-cursor-pointer tw-flex">
            <span class="tw-font-bold tw-text-red-500">荆州</span>
            <p class="tw-text-gray-500 tw-ml-1">[ 切换城市 ]</p>
          </div>
          <div class="tw-cursor-pointer tw-ml-4 tw-text-gray-500">
            <i class="iconfont icon-shouji tw-text-gray-400 tw-mr-1"></i><span>金桥APP</span>
          </div>
          <div class="tw-cursor-pointer tw-ml-4 tw-text-gray-500">
            <i class="iconfont icon-shouye tw-text-gray-400 tw-mr-1"></i><span>金桥首页</span>
          </div>
        </div>
        <div class="tw-flex">
          <div class="tw-text-gray-500 tw-mr-4 tw-text-red-500 tw-cursor-pointer"
            @click="$router.push('/user/login/login')" v-if="!store.isLogin">
            登录 / 注册
          </div>
          <div class="tw-text-gray-500 tw-mr-4 tw-text-red-500 tw-cursor-pointer tw-flex tw-text-center" v-else>
            {{ store.user?.username }}
            <!-- <el-button link type="plain" size="small">退出</el-button> -->
            <el-link :underline="false" class="tw-text-xs tw-ml-2" @click="logout">退出</el-link>
          </div>
          <div class="
              tw-flex tw-items-center tw-text-gray-500 tw-px-3 tw-cursor-pointer
            " @click="$router.push('/user/index')">
            <div class="tw-text-xs">个人中心</div>
            <i class="iconfont icon-arrow-down tw-ml-1"></i>
          </div>
          <div class="
              tw-flex tw-items-center tw-text-gray-500 tw-px-3 tw-cursor-pointer
            " @click="handleClickBusinessCenter">
            <div class="tw-text-xs">商家中心</div>
            <i class="iconfont icon-arrow-down tw-ml-1"></i>
          </div>
          <div class="
              tw-flex tw-items-center tw-text-gray-500 tw-px-3 tw-cursor-pointer
            ">
            <div class="tw-text-xs">帮助中心</div>
            <i class="iconfont icon-arrow-down tw-ml-1"></i>
          </div>
          <div class="
              tw-flex tw-items-center tw-text-gray-500 tw-px-3 tw-cursor-pointer
            ">
            <div class="tw-text-xs">联系客服</div>
            <i class="iconfont icon-arrow-down tw-ml-1"></i>
          </div>
          <div class="
              tw-flex tw-items-center tw-text-gray-500 tw-px-3 tw-cursor-pointer
            ">
            <div class="tw-text-xs">网站导航</div>
            <i class="iconfont icon-arrow-down tw-ml-1"></i>
          </div>
        </div>
      </div>
    </div>
    <router-view />
    <footer>
      <div class="tw-w-full tw-bg-white tw-mt-8">
        <div class="w-1200 tw-py-10 tw-flex tw-justify-between">
          <div href=""><img src="@/assets/img/flogo.jpg" alt="" /></div>
          <div class="footNav">
            <div class="footNav-tit tw-cursor-pointer tw-text-base tw-mb-4">
              关于我们
            </div>
            <div class="
                tw-cursor-pointer tw-text-gray-400 tw-leading-8
                hover:tw-text-red-500
              ">
              服务条款
            </div>
            <div class="
                tw-cursor-pointer tw-text-gray-400 tw-leading-8
                hover:tw-text-red-500
              ">
              免责声明
            </div>
            <div class="
                tw-cursor-pointer tw-text-gray-400 tw-leading-8
                hover:tw-text-red-500
              ">
              实体人才市场
            </div>
            <div class="
                tw-cursor-pointer tw-text-gray-400 tw-leading-8
                hover:tw-text-red-500
              ">
              联系我们
            </div>
          </div>
          <div class="footNav">
            <div class="footNav-tit tw-cursor-pointer tw-text-base tw-mb-4">
              帮助中心
            </div>
            <div class="
                tw-cursor-pointer tw-text-gray-400 tw-leading-8
                hover:tw-text-red-500
              ">
              常见问题
            </div>
            <div class="
                tw-cursor-pointer tw-text-gray-400 tw-leading-8
                hover:tw-text-red-500
              ">
              更多帮助
            </div>
            <div class="
                tw-cursor-pointer tw-text-gray-400 tw-leading-8
                hover:tw-text-red-500
              ">
              意见反馈
            </div>
            <div class="
                tw-cursor-pointer tw-text-gray-400 tw-leading-8
                hover:tw-text-red-500
              ">
              隐私权条款
            </div>
          </div>
          <div class="footNav">
            <div class="footNav-tit tw-cursor-pointer tw-text-base tw-mb-4">
              服务支持
            </div>
            <div class="
                tw-cursor-pointer tw-text-gray-400 tw-leading-8
                hover:tw-text-red-500
              ">
              渠道招商
            </div>
            <div class="
                tw-cursor-pointer tw-text-gray-400 tw-leading-8
                hover:tw-text-red-500
              ">
              推广服务
            </div>
            <div class="
                tw-cursor-pointer tw-text-gray-400 tw-leading-8
                hover:tw-text-red-500
              ">
              举报平台
            </div>
          </div>
          <div class="footNav">
            <div class="footNav-tit tw-cursor-pointer tw-text-base tw-mb-4">
              会员服务
            </div>
            <div class="
                tw-cursor-pointer tw-text-gray-400 tw-leading-8
                hover:tw-text-red-500
              ">
              推广热线
            </div>
            <div class="
                tw-cursor-pointer tw-text-gray-400 tw-leading-8
                hover:tw-text-red-500
              ">
              会员介绍
            </div>
            <div class="
                tw-cursor-pointer tw-text-gray-400 tw-leading-8
                hover:tw-text-red-500
              ">
              开通招聘会员
            </div>
            <div class="
                tw-cursor-pointer tw-text-gray-400 tw-leading-8
                hover:tw-text-red-500
              ">
              付款方式
            </div>
          </div>
          <div class="footCode tw-flex tw-mt-6">
            <div class="footCode-item tw-mx-6 tw-text-center">
              <img src="@/assets/img/ewm.jpg" alt="" class="tw-w-20 tw-h-20" />
              <p class="tw-mt-1">中意小程序</p>
            </div>
            <div class="footCode-item tw-mx-6 tw-text-center">
              <img src="@/assets/img/ewm.jpg" alt="" class="tw-w-20 tw-h-20" />
              <p class="tw-mt-1">中意APP</p>
            </div>
          </div>
        </div>
        <div class="homeFoot w-1200 tw-py-5 tw-flex tw-flex-col tw-items-center">
          <div class="tw-text-gray-500 tw-leading-6">
            联系地址：荆州市北京路219号广源大厦1，2楼 联系电话：(0716)8110733
            邮箱：13902444818@163.com
          </div>
          <div class="tw-text-gray-500 tw-leading-6">
            Copyright © 2017 荆州市中意金桥信息网. All rights reserved.
          </div>
          <div class="tw-flex tw-leading-6">
            <div class="tw-cursor-pointer tw-text-gray-500">内网登录</div>
            <div class="tw-cursor-pointer tw-text-gray-500">
              鄂ICP备11005833号-6
            </div>
          </div>
        </div>
      </div>
    </footer>

    <!-- 聊天窗口最小化 -->
    <!-- <div class="im-min tw-flex tw-items-center tw-justify-between" v-if="initialized() && !store.imDialogVisible">
      <div class="tw-flex tw-items-center ">
        <el-icon size="36" color="#ccc" class="tw-ml-1">
          <ChatDotRound />
        </el-icon>
        <span class="tw-ml-2 tw-text-base">微聊</span>
      </div>
      <div>
        <el-icon size="20" @click="store.imDialogVisible=true">
          <Upload />
        </el-icon>
        <el-icon size="20" class="tw-mx-4">
          <Rank />
        </el-icon>
      </div> 
    </div>-->

    <!-- 聊天窗口 -->

  </div>
</template>

<script>
import { store } from "@/store";
// import { app } from "@/main";
import { existCompany } from '@/utils/api'

export default {
  name: "HomePageLayout",
  components: {},
  data() {
    return {
      //token: localStorage.getItem('token')
      store,
      imDialogVisible: false
    };
  },
  created() {
    try {
      store.setLogin(localStorage.getItem("isLogin") == "true" || localStorage.getItem("isLogin") == true)
      store.setUser(JSON.parse(localStorage.getItem("user")))
    } catch (error) {
      console.log(error)
      store.setLogin(false)
      localStorage.setItem("isLogin", false)
      localStorage.removeItem("user")
    }
  },
  mounted() {
    console.log("store.isLogin:" + store.isLogin);
  },
  methods: {
    initialized() {
      console.log(this.$uikit)
      return !!this.$uikit
    },
    logout() {
      store.setLogin(false)
      localStorage.setItem("isLogin", false)
      localStorage.removeItem('user')
      localStorage.removeItem('token')
      localStorage.removeItem('expire')
      this.$router.push('/user/login/login')
    },
    //点击发布职位按钮事件
    handleClickBusinessCenter() {
      //首先检查当前用户是否已填写公司信息，若无则跳转到添加公司信息页面，如有则跳发布招聘信息页面
      existCompany({}).then((res) => {
        if (res.code === 0) {
          if (res.data == true) {
            this.$router.push("/business/job/index");
          } else {
            this.$router.push("/user/company/add1");
          }
        }
      });
    },
  }
};
</script>

<style scoped lang="scss">
.head-top {
  border-bottom: 1px solid #ffd4d4;

  i {
    font-size: 12px;
  }
}

.homeFoot {
  border-top: 1px sodivd #eeeeee;
}

.im-min {
  position: fixed;
  bottom: 0;
  right: 50px;
  background-color: #eeeeee;
  height: 56px;
  width: 272px;
  border-radius: 10px 10px 0px 0px;
  -webkit-box-shadow: -10px 0 20px -5px rgba(0, 0, 0, .05);
  box-shadow: -10px 0 20px -5px rgba(0, 0, 0, .05);
}

.im-dialog {
  position: absolute !important;
  bottom: 0 !important;
  right: 50px !important;
}
</style>