import BusinessLayout from "@/components/BusinessLayout.vue";
import Job from "@/router/business/modules/Job";
import Buy from "@/router/business/modules/Buy";

const routes = [
    {
        path: '/business',
        component: BusinessLayout,
        children: [
            ...Job,
            ...Buy
        ]

    }
]

export default routes
