import CompanyLayout from "@/views/User/Company/Layout/CompanyLayout";

const routes = [{
        path: '/user/company',
        component: CompanyLayout,
        // redirect: '/user/job/index',
        redirect: '/user/company/list',
        children: [{
                path: '/user/company/list',
                component: () =>
                    import ('@/views/User/Company/List')
            },
            {
                path: '/user/company/detail',
                component: () => import('@/views/User/Company/Detail')
            },
            {
                path: '/user/company/add1',
                component: () =>
                    import ('@/views/User/Company/Add1')
            },
            {
                path: '/user/company/add2',
                component: () =>
                    import ('@/views/User/Company/Add2')
            },
            {
                path: '/user/company/add3',
                component: () =>
                    import ('@/views/User/Company/Add3')
            },
            {
                path: '/user/company/process',
                component: () =>
                    import ('@/views/User/Company/Process')
            },
            {
                path: '/user/company/process2',
                component: () =>
                    import ('@/views/User/Company/Process2')
            },
            {
                path: '/user/company/process3',
                component: () =>
                    import ('@/views/User/Company/Process3')
            }
        ]
    },

]

export default routes